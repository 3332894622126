import preview from '../preview-template/preview-template.vue';
import tinyEditor from '../tinymce/tinymce-editor.vue';
import defaultEmails from '../default-email/default-email.vue';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import store from '@/store/store';

export default {
  components: { preview, defaultEmails, tinyEditor },
  data() {
    return {
      template: {
        name: '',
        subject: '',
        description: '',
        from: '',
        defaultEmailAddresses: {},
        isOnDoNotSendList: false,
        isResendable: false,
        isTrackingPixel: false,
        body: '',
        applicationId: '',
        userId: ''
      },
      defaultEmailAddresses: {
        From: '',
        To: [],
        Cc: [],
        Bcc: []
      },
      step: 1,
      stepTwoLoading: false,
      processing: false
    };
  },
  validations: {
    template: {
      name: { required },
      subject: { required, maxLength: maxLength(998) },
      from: { required, email }
    }
  },
  computed: {
    ...mapState('applications', ['currentApplication']),
    ...mapState('templates', ['currentTemplate']),
    ...mapState('auth', ['user']),
    nameErrors() {
      const errors = [];
      if (!this.$v.template.name.$dirty) return errors;
      !this.$v.template.name.required && errors.push('Template Name is required');
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.template.subject.$dirty) return errors;
      !this.$v.template.subject.required && errors.push('Email subject is required');

      // This limit is based off of RFC 2822 for internet message format
      // http://www.faqs.org/rfcs/rfc2822.html
      !this.$v.template.subject.maxLength && errors.push('Email subject must be max of 998 characters');
      return errors;
    },
    fromAddressErrors() {
      const errors = [];
      if (!this.$v.template.from.$dirty) return errors;
      !this.$v.template.from.required && errors.push('From Email Address is required');
      !this.$v.template.from.email && errors.push('You must enter a valid from email address');
      return errors;
    }
  },
  methods: {
    bodyRecieved(value) {
      this.template.body = value;
    },
    assignDefaultEmailAddresses(newAddresses) {
      this.defaultEmailAddresses = newAddresses;
    },
    async submitTemplate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.processing = true;
      this.template.defaultEmailAddresses = this.defaultEmailAddresses;
      this.template.defaultEmailAddresses.From = this.template.from;
      this.template.userId = this.user.commonid;
      try {
        await store.dispatch('templates/createTemplate', { applicationId: this.$route.params.id, template: this.template });
        if (this.step === 1) {
          this.$router.push({
            name: 'template',
            params: {
              id: this.currentApplication.id,
              templateId: this.currentTemplate.id,
              versionId: this.currentTemplate.versionId
            }
          });
        }
      } catch (e) {
        let errorMessage = 'There was an error submitting the template, please verify the inputs and try again. If this issue persists, please send us an email using the link in the footer below.';
        this.$snotify.error(errorMessage, { timeout: 0, buttons: [{ text: 'Ok', action: null }], closeOnClick: false, icon: false, bodyMaxLength: 200 });
      }
      this.processing = false;
    }
  }
};
